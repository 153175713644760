import React from 'react'
import { useHistory } from 'react-router-dom'
import { PageSection, Title } from '@patternfly/react-core'
import useFrontOffice from 'src/hooks/useFrontOffice'

import { generateRequestFilter, generateRequestSort } from 'src/app/utils/ApiUtils'
import { GenericAsyncTableData, RequestData } from 'src/backoffice/app/components/GenericAsyncTableData'
import {
  PATH_TO_BO_DETTAGLIO_RICHIESTA_ESONERO,
  PATH_TO_BO_ELENCO_RICHIESTE_ESONERO,
  PATH_TO_FO_DETTAGLIO_RICHIESTA_ESONERO,
  PATH_TO_FO_ELENCO_RICHIESTE_ESONERO
} from 'src/app/utils/RoutesConstants'
import { DETAILS_ACTIONS } from 'src/formazione'
import { getDefaultAxiosConfig, getFormazioneResourceApi } from 'src/app/utils/ManagerRestGateway'

import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import { LABEL_STATI, STATI_ESONERO } from 'src/model/Esoneri'

const LISTA_STATI_RICHIESTE_ESONERO = ['In compilazione', 'In valutazione', 'Approvata', 'Richiesta rifiutata', 'Richiesta integrazione']

const Spacer = () => (
  <>
    <br></br>
  </>
)
const basePath = { label: 'Home', link: '/' }
const ElencoRichiesteEsonero: React.FunctionComponent = () => {
  const history = useHistory()
  const isFrontOffice = useFrontOffice()

  const myPath = {
    label: 'Elenco Richieste Esonero',
    link: isFrontOffice ? PATH_TO_FO_ELENCO_RICHIESTE_ESONERO : PATH_TO_BO_ELENCO_RICHIESTE_ESONERO,
  }

  const [sdaf, setSdaf] = React.useState<any>([])
  const [attivita, setAttivita] = React.useState<any>([])

  const loadData = async (request: RequestData) => {
    let customFunc = 'msfoFormazioneEsoneriForUserGet'
    if (!isFrontOffice) customFunc = 'msfoFormazioneEsoneriGet'

    const { data } = await getFormazioneResourceApi()[customFunc](
      generateRequestFilter(request.filters),
      request.pageSize,
      request.currentPage,
      generateRequestSort(request.sortColumn, request.sortDirection),
      getDefaultAxiosConfig()
    )

    setSdaf(sdaf)
    setAttivita(attivita)
    return {
      items: data.items || [],
      total: data.count || 0,
    }
  }

  const onDetails = (row, modalita) => () => {
    history.push({
      pathname: PATH_TO_BO_DETTAGLIO_RICHIESTA_ESONERO,
      search: `?id=${row.id}${modalita ? `&modalita=${modalita}` : ''}`,
    })
  }

  const onDetailsFo = (row, modalita) => () => {
    history.push({
      pathname: PATH_TO_FO_DETTAGLIO_RICHIESTA_ESONERO,
      search: `?id=${row.id}${modalita ? `&modalita=${modalita}` : ''}`,
    })
  }

  return (
    <PageSection>
      <div className="container register-container">
        <GenericBreadCrumb paths={[basePath, myPath]} />
        <Spacer />

        <Title headingLevel="h1" size="lg">
          {myPath.label}
        </Title>
        <Spacer />
        <GenericAsyncTableData
          emptyMessage={'Nessuna richiesta esonero trovata'}
          filters={[
            {
              label: 'Stato',
              field: 'stato',
              type: 'select',
              options: Object.keys(LABEL_STATI).map((ogg) => {
                return { label: LABEL_STATI[ogg], value: ogg }
              }),
            },
            { label: 'Numero Iscrizione', field: 'soggetto.numero_iscrizione_ordine', type: 'input', inputType: 'text', operator: 'EQUAL' },
            { label: 'Cognome', field: 'soggetto.cognome', type: 'input', inputType: 'text', operator: 'LIKE' },
            { label: 'Codice Fiscale', field: 'soggetto.cf', type: 'input', inputType: 'text', operator: 'LIKE' },
          ]}
          pageSizeOptions={[20, 30, 50]}
          columns={[
            {
              label: 'Numero Iscrizione',
              field: 'soggetto.numero_iscrizione_ordine',
              isSortable: false,
              render: (row) => row?.soggetto?.numero_iscrizione_ordine
            },
            { label: 'Nome', field: 'soggetto.nome', isSortable: false, render: (row) => row?.soggetto?.nome },
            { label: 'Cognome', field: 'soggetto.cognome', isSortable: false, render: (row) => row?.soggetto?.cognome },
            { label: 'Codice fiscale', field: 'soggetto.cf', isSortable: false, render: (row) => row?.soggetto?.cf },
            { label: 'Stato', field: 'stato', isSortable: false, render: (row) => LABEL_STATI[row.stato] },
            { label: 'Data inizio', field: 'data_inizio', isSortable: true },
            { label: 'Data fine', field: 'data_fine', isSortable: true },
          ]}
          tableKey={'elenco-richieste-esonero'}
          getRows={loadData}
          getRowActions={(row) => {
            if (isFrontOffice) {
              const showModifica = row.stato === STATI_ESONERO.INTEGRAZIONE || row.stato === STATI_ESONERO.IN_COMPILAZIONE
              const actions = [
                {
                  label: 'Dettaglio richiesta',
                  onClick: () => {
                    history.push({
                      pathname: PATH_TO_FO_DETTAGLIO_RICHIESTA_ESONERO,
                      search: `?id=${row.id}`,
                    })
                  },
                },
              ]
              if (showModifica) {
                actions.push({ label: 'Modifica', onClick: onDetailsFo(row, DETAILS_ACTIONS.MODIFICA) })
              }
              return actions
            }
            return row.stato === STATI_ESONERO.INVIATA
              ? [
                  {
                    label: 'Dettaglio richiesta',
                    onClick: () => {
                      history.push({
                        pathname: PATH_TO_BO_DETTAGLIO_RICHIESTA_ESONERO,
                        search: `?id=${row.id}`,
                      })
                    },
                  },
                  { label: 'Approva', onClick: onDetails(row, DETAILS_ACTIONS.APPROVA) },
                  { label: 'Rigetta', onClick: onDetails(row, DETAILS_ACTIONS.RIGETTA) },
                  { label: 'Richiesta di integrazione', onClick: onDetails(row, DETAILS_ACTIONS.RICHIESTA_INTEG) },
                ]
              : [
                  {
                    label: 'Dettaglio richiesta',
                    onClick: () => {
                      history.push({
                        pathname: PATH_TO_BO_DETTAGLIO_RICHIESTA_ESONERO,
                        search: `?id=${row.id}`,
                      })
                    },
                  },
                ]
          }}
        />
      </div>
    </PageSection>
  )
}

export default ElencoRichiesteEsonero
