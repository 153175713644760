import { Col, Container, Icon, Row } from 'design-react-kit'
import React from 'react'
import conafLogo from '../images/conaf-logo.png'
import { getHome, goTo } from 'src/utilities/utility'
import { PATH_TO_FO_COOKIE, PATH_TO_FO_FEEDBACK_ACCESSIBILITA, PATH_TO_FO_PRIVACY } from 'src/app/utils/RoutesConstants'

const facebook = 'https://www.facebook.com/agronomiforestali/?locale=it_IT';
const instagram = 'https://instagram.com/ordine_agronomi_e_forestali?igshid=MzRlODBiNWFlZA==';
const twitter = 'https://twitter.com/_conaf';
const mailto = 'ufficioprotocollo@conaf.it';

class GenericFooter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <>
                    <footer className="it-footer" style={{marginTop: '10%'}}>
                        <div className="it-footer-main">
                            <Container>
                            <section>
                                <Row className="clearfix">
                                    <Col sm={12}>
                                        <div className="it-brand-wrapper">
                                            <a
                                                className=""
                                                href="#"
                                            >
                                                    <div>
                                                        <img onClick={() => {goTo(getHome())}} src={conafLogo} alt="conaf-logo" />
                                                    </div>
                                                    <div className="it-brand-text p-2">
                                                        <h2>
                                                            SISTEMA INFORMATIVO DEI DOTTORI AGRONOMI E FORESTALI
                                                        </h2>
                                                        <h3 className="d-none d-md-block">
                                                            Consiglio Nazionale dei dottori agronomi e forestali
                                                        </h3>
                                                </div>
                                            </a>
                                        </div>

                                    </Col>
                                </Row>
                            </section>
                            <section className="py-4 border-white border-top">
                                <Row>

                                <Col
                                    className="pb-2"
                                    lg={6}
                                    md={6}
                                >
                                    <h4>
                                        Contatti
                                    </h4>
                                    <ul className="list-inline text-left social">
                                        <li className="list-inline-item">
                                                <a
                                                    className="p-2 text-white"
                                                    aria-label="E-mail"
                                                    href={"mailto:"+mailto}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <Icon
                                                        className="align-top"
                                                        color="white"
                                                        size="sm"
                                                        icon="it-mail"
                                                    />
                                                    <span className="sr-only p-2">
                                                        E-mail
                                                    </span>
                                                </a>
                                        </li>
                                    </ul>
                                </Col>
                                <Col
                                    className="pb-2"
                                    lg={6}
                                    md={6}
                                >
                                    <h4>
                                        Seguici su
                                    </h4>
                                    <ul className="list-inline text-left social">
                                        <li className="list-inline-item">
                                            <a
                                                className="p-2 text-white"
                                                aria-label="Facebook"
                                                href={facebook}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <Icon
                                                    className="align-top"
                                                    color="white"
                                                    size="sm"
                                                    icon="it-facebook"
                                                />
                                                <span className="sr-only">
                                                    Facebook
                                                </span>
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a
                                                className="p-2 text-white"
                                                aria-label="Twitter"
                                                href={twitter}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <Icon
                                                    className="align-top"
                                                    color="white"
                                                    size="sm"
                                                    icon="it-twitter"
                                                />
                                                <span className="sr-only">
                                                    Twitter
                                                </span>
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a
                                                className="p-2 text-white"
                                                aria-label="Instagram"
                                                href={instagram}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <Icon
                                                    className="align-top"
                                                    color="white"
                                                    size="sm"
                                                    icon="it-instagram"
                                                />
                                                <span className="sr-only">
                                                    Instagram
                                                </span>
                                            </a>
                                        </li>

                                    </ul>
                                </Col>
                                </Row>
                            </section>
                            </Container>
                        </div>
                        <div className="it-footer-small-prints clearfix">
                            <Container>
                            <h3 className="sr-only">
                                Sezione Link Utili
                            </h3>
                            <ul className="it-footer-small-prints-list list-inline mb-0 d-flex flex-column flex-md-row">
                                <li className="list-inline-item">
                                <a
                                    href={PATH_TO_FO_PRIVACY}
                                    title="Privacy policy"
                                >
                                    Privacy policy
                                </a>
                                </li>
                                <li className="list-inline-item">
                                <a
                                    href={PATH_TO_FO_COOKIE}
                                    title="Cookie policy"
                                >
                                    Cookie policy
                                </a>
                                </li>
                                <li className="list-inline-item">
                                <a
                                    href="https://form.agid.gov.it/view/1014b95a-767c-4c76-aa07-e5566e3cba58"
                                    title="Dichiarazione accessibilità"
                                >
                                    Dichiarazione accessibilità
                                </a>
                                </li>
                                <li className="list-inline-item">
                                <a
                                    href={PATH_TO_FO_FEEDBACK_ACCESSIBILITA}
                                    title="Feedback accessibilità"
                                >
                                    Feedback accessibilità
                                </a>
                                </li>
                            </ul>
                            </Container>
                        </div>
                        </footer>
            </>
        );
    }
}

export default GenericFooter;
