import * as React from 'react'
import { Form } from '@patternfly/react-core'
import { PATH_TO_BO_ATTI_DISCIPLINARI } from 'src/app/utils/RoutesConstants'
import { GenericButton } from 'src/backoffice/app/components'

var title = 'Cancella Sanzione Disciplinare'

class FormCancellaDisciplinari extends React.Component<any
, any> {
    constructor(props) {
        super(props)
        this.state = {
          isLoaded : false,
          disciplinari:props.disciplinari,
        }
    }

    async componentDidMount(): Promise<void> {
        this.setState({ isLoaded : true })
    }

    handleSubmit = async event => {
        await this.setState({isLoaded:false})
    }

    render() {
        return (
            <div className="container-fluid p-4">
                <div className='row'>
                    <div className='col-10 offset-1'>
                        <Form id="formDisciplinari" className="row" key={'formDisciplinari'}>
                        <h1>{title}</h1>
                        <h2>Sei sicuro di voler procedere all'eliminazione dell'atto disciplinare avente N. delibera: {this.props.disciplinari?.deliberaNr} ?</h2>
                        <div className="row">
                            <div className="col-2 py-4">
                                <GenericButton onClick={()=>this.props.inviaDisciplinari(this.props.data)} key={"succ"} label="Conferma" color={'primary'} className={'btn btn-primary px-3 py-2'} />
                            </div>
                            <div className="col-1 py-4">
                                <GenericButton key={"home"} onClick={()=>this.props.goToDisciplinari(PATH_TO_BO_ATTI_DISCIPLINARI)} label="Chiudi" color={'secondary'} className={'btn btn-secondary px-3 py-2'} />
                            </div>
                        </div>
                        </Form>
                    </div>
                </div>
            </div>
        )
    }
}

export default FormCancellaDisciplinari
export declare interface FormCancellaDisciplinariProps {
    message?: any;
    disciplinari?: any;
    inviaDisciplinari?: any;
}
