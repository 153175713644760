import * as React from 'react'
import '../../Disciplinari/style.scss'
import { Form, FormGroup } from '@patternfly/react-core'
import { PATH_TO_BO_ATTI_DISCIPLINARI } from 'src/app/utils/RoutesConstants'
import GenericCard from 'src/backoffice/app/components/GenericCard'
import { GenericButton, GenericSelect, GenericTextInput } from 'src/backoffice/app/components'
import GenericTextArea from 'src/backoffice/app/components/GenericTextArea'
import {
  listaOrganiDisciplinari,
  listaTipoProvvedimentiDisciplinari
} from 'src/processes/disciplinari/CostantiDisciplinari'
import { DisciplinariUtils } from 'src/processes/disciplinari/DisciplinariUtils'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import { isCurrentMobileDevice } from 'src/utilities/deviceUtility'

const isMobileDevice = isCurrentMobileDevice()

var title = ''
const RADIAZIONE = 'radiazione'
const SOSPENSIONE_MOROSITA = 'sospensione-morosita'
const AVVERTIMENTO = 'avvertimento'

class FormListaDisciplinari extends React.Component<FormListaDisciplinariProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      prevData: props.prevData,
      disciplinari: props.disciplinari,
      visualizzaFlag: props.visualizzaFlag,
      modificaDatiFlag: props.modificaDatiFlag,
      fields: {},
      errors: {},
      isLoaded: false,
      message: '',
      errorFlag: false,
      showDataProvvedimentoAl: true,
      sedeDisabled: false,
      tipoProvvedimentoEmpty: false,
    }
  }
  async componentDidMount(): Promise<void> {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    this.setState({ isLoaded: true })

    if (this.props.visualizzaFlag) {
      title = 'Visualizza Sanzione Disciplinare '
    } else if (this.props.modificaDatiFlag) {
      title = 'Modifica Sanzione Disciplinare '
    } else {
      title = 'Nuova Sanzione Disciplinare '
    }
    //show/hide input -sede on click og organo options
    if (
      this.state.prevData.organo === 'corte_di_appello' ||
      this.state.prevData.organo === 'tribunale' ||
      this.state.prevData.organo === 'consiglio_provinciale' ||
      this.state.disciplinari.organo === 'corte_di_appello' ||
      this.state.disciplinari.organo === 'tribunale' ||
      this.state.disciplinari.organo === 'consiglio_provinciale'
    ) {
      this.setState({ disciplinari: this.checkRuoloPerSede(this.state.disciplinari) })
      this.setState({ showSede: true })
    } else this.setState({ showSede: false })

    //show/hide data inizio provvedimento e data fine provvedimento on click of tipo provvedimento
    if (this.state.prevData.provvedimento === RADIAZIONE) this.setState({ showDataProvvedimentoAl: false })

    if (this.state.disciplinari.provvedimento === SOSPENSIONE_MOROSITA && this.state.disciplinari.provvedimentoAl.includes('9999')) {
      this.state.disciplinari.provvedimentoAl = null
    }
    if (this.state.prevData.provvedimento === 'avvertimento') this.setState({ showDatas: false })
    else this.setState({ showDatas: true })

    if (title.includes('Nuova Sanzione Disciplinare')) {
    } else {
      let disciplinari = this.state.disciplinari
      if (typeof disciplinari.organo !== 'object') disciplinari.organo = DisciplinariUtils.decodificaOrgano(disciplinari.organo)
      if (typeof disciplinari.provvedimento !== 'object') disciplinari.provvedimento = DisciplinariUtils.decodificaTipoProvvedimento(disciplinari.provvedimento)
      if (typeof disciplinari.provvedimento === 'undefined') this.setState({ tipoProvvedimentoEmpty: true })
      this.setState({ disciplinari: disciplinari })
    }
  }
  checkRuoloPerSede = async (disciplinariInput: any) => {
    let disciplinari = disciplinariInput
    this.setState({ showSede: false })
    if (disciplinari.organo === 'consiglio_provinciale' || disciplinari.organo === 'corte_di_appello' || this.state.disciplinari.organo === 'tribunale') {
      if (TokenStore.getInstance().isCurrentAccountODAF()) {
        //In questo caso il campo "sede" va disabilitato e precompilato con la provincia dell'ordine competente
        await TokenStore.getInstance()
          .getDenominazioneOfCurrentAccount()
          .then((response) => {
            //Returns -> Operatore dell'ordine di <PROVINCIA>
            //Prendo solo la <PROVINCIA>
            let provincia = response.split("Operatore dell'ordine di ")[1]

            disciplinari.chiProcede = provincia
          })
        await this.setState({ sedeDisabled: true })
      }
      if (TokenStore.getInstance().isCurrentAccountCONAF()) {
        //In questo caso il campo "sede" va abilitato e lasciato vuoto
        this.setState({ sedeDisabled: false })
      }
    } else {
      disciplinari.chiProcede = ''
      this.setState({ sedeDisabled: false })
    }
    //Returns -> Operatore dell'ordine di <PROVINCIA>
    //Prendo solo la <PROVINCIA>

    this.setState({ disciplinari: disciplinari })
    this.setState({ showSede: true })
    return disciplinari
  }

  hasFormErrors = (alert) => {
    var findItem = this.state.errors.find((a) => a.id === alert.id)
    var errorID = this.state.errors.indexOf(findItem)
    if (findItem) {
      if (alert.isHidden) this.state.errors[errorID] = { id: alert.id, isDisabled: alert.isDisabled, isHidden: alert.isHidden, stepTab: alert.stepTab }
      else this.state.errors[errorID] = { error: alert.hasError, id: alert.id, isDisabled: alert.isDisabled, isHidden: alert.isHidden, stepTab: alert.stepTab }
    } else {
      this.state.errors.push({ error: alert.hasError, id: alert.id, isDisabled: alert.isDisabled, isHidden: alert.isHidden, stepTab: alert.stepTab })
    }
  }

  render() {
    const setDataItem = async (e: any, type: any, field: any) => {
      let fields = this.state.disciplinari

      if (type === 'select') {
        fields[field] = e

        if (field === 'provvedimento') {
          this.setState({ showDataProvvedimentoAl: true })
          if (e === RADIAZIONE) {
            this.setState({ showDataProvvedimentoAl: false })
          }
          if (e === AVVERTIMENTO) {
            this.setState({ showDatas: false })
          } else {
            this.setState({ showDatas: true })
          }
        }
        if (field === 'organo') {
          if (e === 'consiglio_provinciale' || e === 'tribunale' || e === 'corte_di_appello') {
            await this.checkRuoloPerSede(this.state.disciplinari).then((response) => {
              fields.chiProcede = response.chiProcede
              this.setState({ showSede: true })
            })
          } else {
            this.setState({ showSede: false })
          }
        }
      } else if (type === 'textarea') {
        fields[field] = e
      } else if (type === 'input') {
        fields[field] = e
      } else {
        fields[field] = e.target.value
      }
      this.setState({ fields: fields, isLoaded: true })

      this.props.getSelectedValue(fields)
    }
    return (
      <>
        {this.state.isLoaded && (
          <div className="container-fluid px-5 pt-5">
            <div className="row">
              <div className="col-10 offset-1">
                <Form id="formDisciplinari" className="row" key={'formDisciplinari'}>
                  <h1>{title}</h1>
                  <GenericCard
                    key={'procedimentoDisciplinari'}
                    header="PROCEDIMENTI DISCIPLINARI"
                    className={'p-4'}
                    body={
                      <>
                        <div className="row">
                          <div className={isCurrentMobileDevice() ? null : 'col-12'}>
                            <FormGroup label={'Procedimento nr'} isRequired>
                              <GenericTextInput
                                onChange={(e) => setDataItem(e, 'input', 'procedimentoNr')}
                                isDisabled={this.props.visualizzaFlag}
                                isRequired
                                key={'procedimentoNr'}
                                id="procedimentoNr"
                                name="Procedimento nr"
                                placeholder={!this.props.visualizzaFlag ? 'Inserire numero procedimento' : '-'}
                                defaultValue={this.state.disciplinari['procedimentoNr'] ? this.state.disciplinari['procedimentoNr'] : null}
                                type={'text'}
                              />
                            </FormGroup>
                          </div>
                        </div>
                        <div className="row">
                          <div className={isCurrentMobileDevice() ? null : 'col-12'}>
                            <FormGroup label={'Causale'} isRequired>
                              <GenericTextArea
                                className="pl-3"
                                name="Causale"
                                onChange={(e) => setDataItem(e, 'textarea', 'causale')}
                                isDisabled={this.props.visualizzaFlag}
                                isRequired
                                key={'causale'}
                                id="causale"
                                placeholder={!this.props.visualizzaFlag ? 'Inserire causale' : '-'}
                                value={this.state.disciplinari['causale'] ? this.state.disciplinari['causale'] : null}
                              />
                            </FormGroup>
                          </div>
                        </div>
                        <div className="row my-3">
                          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                            <FormGroup label={'Organo'} isRequired>
                              <GenericSelect
                                isHidden={this.props.visualizzaFlag}
                                value={this.state.disciplinari['organo'] ? this.state.disciplinari['organo'].value : 'cassazione'}
                                key={'organo'}
                                onChange={(e) => {
                                  setDataItem(e, 'select', 'organo')
                                }}
                                id="organo"
                                isRequired
                                list={listaOrganiDisciplinari}
                                placeholder={!this.props.visualizzaFlag ? 'Seleziona organo' : '-'}
                                isDisabled={this.props.visualizzaFlag}
                              />
                              <GenericTextInput
                                isHidden={!this.props.visualizzaFlag}
                                isDisabled={this.props.visualizzaFlag}
                                key={'organo'}
                                id="organo"
                                name="organo"
                                defaultValue={this.state.disciplinari['organo'] ? this.state.disciplinari['organo'].label : '-'}
                                type={'text'}
                              />
                            </FormGroup>
                          </div>
                          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                            {this.state.showSede === true && (
                              <FormGroup label={'Sede'} isRequired>
                                <GenericTextInput
                                  onChange={(e) => setDataItem(e, 'input', 'chiProcede')}
                                  isDisabled={this.props.visualizzaFlag || this.state.sedeDisabled}
                                  isRequired
                                  key={'chiProcede'}
                                  id="chiProcede"
                                  name="chiProcede"
                                  placeholder={!this.props.visualizzaFlag ? 'Inserire sede' : '-'}
                                  defaultValue={this.state.disciplinari['chiProcede'] ? this.state.disciplinari['chiProcede'] : null}
                                  type={'text'}
                                />
                              </FormGroup>
                            )}
                          </div>
                        </div>

                        <div className="row">
                          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                            <FormGroup label={'Data apertura'} isRequired>
                              <GenericTextInput
                                onChange={(e) => setDataItem(e, 'input', 'dataApertura')}
                                defaultValue={this.state.disciplinari['dataApertura'] ? this.state.disciplinari['dataApertura'] : null}
                                id={'dataApertura'}
                                placeholder={'Selezionare data'}
                                isDisabled={this.props.visualizzaFlag}
                                type={'date'}
                              />
                            </FormGroup>
                          </div>
                          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                            <FormGroup label={'Data chiusura'} isRequired>
                              <GenericTextInput
                                onChange={(e) => setDataItem(e, 'input', 'dataChiusura')}
                                defaultValue={this.state.disciplinari['dataChiusura'] ? this.state.disciplinari['dataChiusura'] : null}
                                id={'dataChiusura'}
                                placeholder={'Selezionare data'}
                                isDisabled={this.props.visualizzaFlag}
                                type={'date'}
                              />
                            </FormGroup>
                          </div>
                        </div>
                        <div className="row">
                          <div className={isCurrentMobileDevice() ? null : 'col-12 my-3'}>
                            <FormGroup label={'Determinazione'} isRequired>
                              <GenericTextArea
                                onChange={(e) => setDataItem(e, 'textarea', 'determinazione')}
                                isDisabled={this.props.visualizzaFlag}
                                isRequired
                                className="pl-3"
                                name="Determinazione"
                                key={'determinazione'}
                                id="determinazione"
                                placeholder={!this.props.visualizzaFlag ? 'Inserire determinazione' : '-'}
                                value={this.state.disciplinari['determinazione'] ? this.state.disciplinari['determinazione'] : null}
                              />
                            </FormGroup>
                          </div>
                        </div>
                      </>
                    }
                  ></GenericCard>
                  <GenericCard
                    key={'provvedimenti'}
                    header="PROVVEDIMENTI"
                    className={'p-4'}
                    body={
                      <>
                        <div className="row">
                          <div className={isCurrentMobileDevice() ? null : 'col-6 mb-3'}>
                            <FormGroup label={'Tipo provvedimento'} isRequired>
                              <GenericSelect
                                isHidden={this.props.visualizzaFlag || (this.props.modificaDatiFlag && !this.state.tipoProvvedimentoEmpty)}
                                onChange={(e) => {
                                  setDataItem(e, 'select', 'provvedimento')
                                }}
                                //value={ this.state.disciplinari['provvedimento'] ? this.state.disciplinari['provvedimento'] : null}
                                value={this.state.disciplinari['provvedimento'] ? this.state.disciplinari['provvedimento'].value : 'avvertimento'}
                                key={'provvedimento'}
                                list={listaTipoProvvedimentiDisciplinari}
                                isDisabled={this.props.visualizzaFlag || (this.props.modificaDatiFlag && !this.state.tipoProvvedimentoEmpty)}
                                id="provvedimento"
                                placeholder={!this.props.visualizzaFlag ? 'Seleziona provvedimento' : '-'}
                              />
                              <GenericTextInput
                                isHidden={!this.props.visualizzaFlag && !(this.props.modificaDatiFlag && !this.state.tipoProvvedimentoEmpty)}
                                isDisabled={this.props.visualizzaFlag || (this.props.modificaDatiFlag && !this.state.tipoProvvedimentoEmpty)}
                                key={'provvedimento'}
                                id="provvedimento"
                                name="provvedimento"
                                defaultValue={this.state.disciplinari['provvedimento'] ? this.state.disciplinari['provvedimento'].label : '-'}
                                type={'text'}
                              />
                            </FormGroup>
                          </div>
                        </div>
                        <div className="row">
                          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                            <FormGroup label={'Data delibera'} isRequired>
                              <GenericTextInput
                                onChange={(e) => setDataItem(e, 'input', 'dataProvvedimento')}
                                defaultValue={this.state.disciplinari['dataProvvedimento'] ? this.state.disciplinari['dataProvvedimento'] : null}
                                id={'dataProvvedimento'}
                                placeholder={'Selezionare data'}
                                isDisabled={this.props.visualizzaFlag}
                                type={'date'}
                              />
                            </FormGroup>
                          </div>
                          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                            <FormGroup label={'Delibera nr'} isRequired>
                              <GenericTextInput
                                onChange={(e) => setDataItem(e, 'input', 'deliberaNr')}
                                key={'deliberaNr'}
                                id="deliberaNr"
                                name="Delibera nr"
                                isDisabled={this.props.visualizzaFlag}
                                isRequired
                                placeholder={!this.props.visualizzaFlag ? 'Inserire numero delibera' : '-'}
                                defaultValue={this.state.disciplinari['deliberaNr'] ? this.state.disciplinari['deliberaNr'] : null}
                                type={'text'}
                              />
                            </FormGroup>
                          </div>
                        </div>
                        <div className="row">
                          {this.state.showDatas === true && (
                            <>
                              <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                                <FormGroup label={'Data inizio provvedimento'} isRequired>
                                  <GenericTextInput
                                    onChange={(e) => setDataItem(e, 'input', 'provvedimentoDal')}
                                    defaultValue={this.state.disciplinari['provvedimentoDal'] ? this.state.disciplinari['provvedimentoDal'] : null}
                                    id={'provvedimentoDal'}
                                    placeholder={'Selezionare data'}
                                    isDisabled={this.props.visualizzaFlag}
                                    type={'date'}
                                  />
                                </FormGroup>
                              </div>
                              <div
                                className={isCurrentMobileDevice() ? null : 'col-6'}
                                hidden={this.state.disciplinari['provvedimento']?.value === RADIAZIONE || !this.state.showDataProvvedimentoAl}
                              >
                                <FormGroup label={'Data fine provvedimento'} isRequired>
                                  <GenericTextInput
                                    onChange={(e) => setDataItem(e, 'input', 'provvedimentoAl')}
                                    defaultValue={this.state.disciplinari['provvedimentoAl'] ? this.state.disciplinari['provvedimentoAl'] : null}
                                    id={'provvedimentoAl'}
                                    placeholder={'Selezionare data'}
                                    isDisabled={this.props.visualizzaFlag}
                                    type={'date'}
                                    isRequired={
                                      this.state.disciplinari['provvedimento']?.value === SOSPENSIONE_MOROSITA ||
                                      this.state.disciplinari['provvedimento'] === SOSPENSIONE_MOROSITA
                                        ? false
                                        : this.state.disciplinari['provvedimento']?.value !== RADIAZIONE || this.state.showDataProvvedimentoAl
                                        ? true
                                        : false
                                    }
                                    isHidden={!this.state.showDataProvvedimentoAl}
                                  />
                                </FormGroup>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="row">
                          <div className={isCurrentMobileDevice() ? null : 'col-12 my-3'}>
                            <FormGroup label={'Motivazione'} isRequired>
                              <GenericTextArea
                                onChange={(e) => setDataItem(e, 'textarea', 'motivazione')}
                                isRequired
                                isDisabled={this.props.visualizzaFlag}
                                className="pl-3"
                                name="Motivazione"
                                key={'motivazione'}
                                id="motivazione"
                                placeholder={!this.props.visualizzaFlag ? 'Inserire motivazione' : '-'}
                                value={this.state.disciplinari['motivazione'] ? this.state.disciplinari['motivazione'] : null}
                              />
                            </FormGroup>
                          </div>
                        </div>
                      </>
                    }
                  ></GenericCard>
                  <GenericCard
                    key={'dateNotifica'}
                    header="DATE NOTIFICA"
                    className={'p-4'}
                    body={
                      <>
                        <div className="row">
                          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                            <FormGroup label={"All'Iscritto"}>
                              <GenericTextInput
                                onChange={(e) => setDataItem(e, 'input', 'dataNotificaIscrizione')}
                                defaultValue={this.state.disciplinari['dataNotificaIscrizione'] ? this.state.disciplinari['dataNotificaIscrizione'] : null}
                                id={'dataNotificaIscrizione'}
                                placeholder={'Selezionare data'}
                                isDisabled={this.props.visualizzaFlag}
                                type={'date'}
                              />
                            </FormGroup>
                          </div>
                          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                            <FormGroup label={'Alla Procura'}>
                              <GenericTextInput
                                onChange={(e) => setDataItem(e, 'input', 'dataNotificaProcuraGenerale')}
                                defaultValue={
                                  this.state.disciplinari['dataNotificaProcuraGenerale'] ? this.state.disciplinari['dataNotificaProcuraGenerale'] : null
                                }
                                id={'dataNotificaProcuraGenerale'}
                                placeholder={'Selezionare data'}
                                isDisabled={this.props.visualizzaFlag}
                                type={'date'}
                              />
                            </FormGroup>
                          </div>
                        </div>
                        <div className="row">
                          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                            <FormGroup label={'Al Ministero della Giustizia'}>
                              <GenericTextInput
                                onChange={(e) => setDataItem(e, 'input', 'dataNotificaMinisteroGiustizia')}
                                defaultValue={
                                  this.state.disciplinari['dataNotificaMinisteroGiustizia'] ? this.state.disciplinari['dataNotificaMinisteroGiustizia'] : null
                                }
                                id={'dataNotificaMinisteroGiustizia'}
                                placeholder={'Selezionare data'}
                                isDisabled={this.props.visualizzaFlag}
                                type={'date'}
                              />
                            </FormGroup>
                          </div>
                          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                            <FormGroup label={'Al Consiglio Nazionale'}>
                              <GenericTextInput
                                onChange={(e) => setDataItem(e, 'input', 'dataNotificaConsiglioNazionale')}
                                defaultValue={
                                  this.state.disciplinari['dataNotificaConsiglioNazionale'] ? this.state.disciplinari['dataNotificaConsiglioNazionale'] : null
                                }
                                id={'dataNotificaConsiglioNazionale'}
                                placeholder={'Selezionare data'}
                                isDisabled={this.props.visualizzaFlag}
                                type={'date'}
                              />
                            </FormGroup>
                          </div>
                        </div>
                        <div className="row">
                          <div className={isCurrentMobileDevice() ? null : 'col-6'}>
                            <FormGroup label={'Alla Procura Generale'}>
                              <GenericTextInput
                                onChange={(e) => setDataItem(e, 'input', 'dataNotificaProcedimento')}
                                defaultValue={this.state.disciplinari['dataNotificaProcedimento'] ? this.state.disciplinari['dataNotificaProcedimento'] : null}
                                id={'dataNotificaProcedimento'}
                                placeholder={'Selezionare data'}
                                isDisabled={this.props.visualizzaFlag}
                                type={'date'}
                              />
                            </FormGroup>
                          </div>
                        </div>
                      </>
                    }
                  ></GenericCard>
                  <div className="confButtons">
                    {/* old css "col-2 py-4" */}
                    <div className="py-4 child1" hidden={this.props.visualizzaFlag}>
                      <GenericButton
                        key={'succ'}
                        label="Salva"
                        onClick={() => {
                          this.props.inviaDisciplinari(this.props.data, this.state.showSede, this.state.showDatas, this.state.showDataProvvedimentoAl)
                        }}
                        color={'primary'}
                        className={'btn btn-primary px-3 py-2'}
                      />
                    </div>
                    {/* old css "col-2 py-4" */}
                    <div className="py-4" hidden={this.props.visualizzaFlag}>
                      <GenericButton
                        key={'home'}
                        label="Chiudi"
                        onClick={() => {
                          this.props.goToDisciplinari(PATH_TO_BO_ATTI_DISCIPLINARI)
                        }}
                        color={'secondary'}
                        className={'btn btn-secondary px-3 py-2'}
                      />
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
}

export default FormListaDisciplinari
export declare interface FormListaDisciplinariProps {
  data?: any
  modificaDatiFlag?: any
  visualizzaFlag?: any
  prevData?: any
  getSelectedValue?: any
  message?: any
  isRequired?: any
  messaggio?: any
  submitMessageResult?: any
  onSubmit?: any
  disciplinari?: any
  inviaDisciplinari?: any
  goToDisciplinari?: any
}
