import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import AccessDenied from './AccessDenied'
import { SidafComponents } from './utils/SidafComponentsIndex'
import NotFound from './NotFound'
import {
  PATH_ROLES,
  PATH_TO_ERROR,
  PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE_VISUALIZZA,
  PATH_TO_FO_COOKIE,
  PATH_TO_FO_DETTAGLIO_EVENTO_CATALOGO,
  PATH_TO_FO_ELENCO_EVENTI_CATALOGO,
  PATH_TO_FO_FEEDBACK_ACCESSIBILITA,
  PATH_TO_FO_PRIVACY,
  PATH_TO_HOME,
  PATH_TO_RICERCA_ALBO_UNICO,
  PATH_TO_RISULTATI_RICERCA_ALBO_UNICO,
} from './utils/RoutesConstants'
import IscrizioneOrdine from 'src/frontend/app/pages/iscrizioneAlbo/IscrizioneOrdine'
import RicercaIscrittiAlboUnico from 'src/frontend/app/pages/RicercaIscrittiAlboUnico'
import Home from 'src/frontend/app/pages/Home'
import RisultatiRicercaAlboUnico from 'src/frontend/app/pages/ricercaAlboUnico/RisultatiRicercaAlboUnico'
import GenericError from 'src/errorBoundary/GenericError'
import { ErrorStore } from 'src/errorBoundary/ErrorStore'
import moment from 'moment'
import { homeComponent } from 'src/utilities/utility'
import ElencoEventiCatalogo from 'src/backoffice/app/pages/formazione/ElencoEventiCatalogo'
import DettaglioEventoCatalogo from 'src/backoffice/app/pages/formazione/DettaglioEventoCatalogo'
import SidafCookieBar from './utils/SidafCookieBar'
import Cookie from 'src/frontend/app/pages/Cookie'
import Privacy from 'src/frontend/app/pages/Privacy'
import GenericModal from 'src/frontend/app/components/GenericModal'
import FeedbackAccessibilita from 'src/frontend/app/pages/FeedbackAccessibilita'

class AppRouter extends React.Component {
  modalTitle = 'ATTENZIONE!'
  emailHelpDesk = 'mailto:helpdesksidaf@conaf.it'
  urlOldConaf = 'https://www.conafonline.it'

  componentDidMount() {
    var showModalSessionStorage = window.sessionStorage.getItem('showModal')

    if (showModalSessionStorage != null) {
      this.setState({ modalOpen: false })
    } else {
      this.setState({ modalOpen: true })
    }
  }

  closeModal = () => {
    var showModalSessionStorage = true
    this.setState({ modalOpen: false })
    window.sessionStorage.setItem('showModal', showModalSessionStorage + '')
  }

  render() {
    /**
     * Controlla se ci sono degli errori, allora rimanda alla pagina di errore
     */
    if (ErrorStore.getInstance().getMessageError()) {
      ErrorStore.getInstance().addError({
        title_error: ErrorStore.getInstance().getErrorTitle(),
        message_error: ErrorStore.getInstance().getMessageError(),
        back_url: ErrorStore.getInstance().getBackUrl(),
        time: moment().format('DD/MM/YYYY HH:mm:ss'),
      })
      return <GenericError></GenericError>
    } else if (
      TokenStore.getInstance().getKeycloak() &&
      TokenStore.getInstance().getKeycloak() !== '' &&
      TokenStore.getInstance().getKeycloak().authenticated
    ) {
      const homeComp = homeComponent()
      /**
       * Costruisco la lista delle route dinamicamente.
       * Nell'array ci saranno i percorsi negati e quelli consentiti con il relativo componente.
       */
      var routes = []
      PATH_ROLES.forEach((roleList, path) => {
        if (!TokenStore.getInstance().currentAccountHasRolesOR(roleList)) {
          routes.push(<Route key={path} path={path} component={AccessDenied} />)
        } else if (path === PATH_TO_FO_ANAG_ISCRIZIONE_ORDINE_VISUALIZZA) {
          routes.push(<Route key={path} exact path={path} render={(props) => <IscrizioneOrdine {...props} isVisualizza={true} />} />)
        } else {
          routes.push(<Route exact key={path} path={path} component={SidafComponents[path]} />)
        }
      })

      /**
       * Costruisco il router sulla base delle routes precedentemente calcolate per l'utente corrente.
       */
      let router = (
        <Router>
          <Switch>
            <Route exact path={'/'} component={homeComp} />
            {routes.map((route) => route)}
            <Route path={'/*'} component={NotFound} />
          </Switch>
          <SidafCookieBar />
          <GenericModal
            modalOpen={this.state?.modalOpen}
            closeButton={this.closeModal}
            title={this.modalTitle}
            size={'lg'}
            centered
            text={
              <div>
                <p>
                  A far data dal 16/09/2024 è possibile procedere con la proposta di adesione alla polizza collettiva RC Professionale CONAF con decorrenza
                  dalle <b>00:00 del 15/10/2024 </b>
                  sino alle ore <b>24:00 del 14/10/2025</b>.
                </p>
                <p>
                  Coloro che volessero procedere con una nuova adesione valida sino alle ore 24:00 del 14/10/2024 devono inviare una specifica richiesta a mezzo
                  PEC a <b>protocollo@conafpec.it</b>.
                </p>
                <br></br>
                <p>
                  Il Servizio di richiesta, rinnovo e gestione dei dispositivi di firma elettronica e il servizio di assegnazione/cancellazione delle caselle
                  e-mail PEC è momentaneamente accessibile all’indirizzo{' '}
                  <a target={'_blank'} href={this.urlOldConaf}>
                    {this.urlOldConaf.substring(8)}
                  </a>{' '}
                  con le modalità già indicate nella circolare CONAF n.19_2023 consultabile al seguente link:{' '}
                  <a target={'_blank'} href="https://www.conaf.it/circolare-n-19-del-28-06-2023/">
                    www.conaf.it/circolare-n-19-del-28-06-2023
                  </a>
                </p>
                <br></br>
                <p>
                  Si segnala inoltre che la piattaforma è in continua evoluzione con il completamento e l’implementazione di funzioni non ancora disponibili.
                </p>
                <br></br>
                {/* <p>Ci scusiamo per qualsiasi momentaneo disagio e vi preghiamo di inviare eventuali segnalazioni all’indirizzo <a href={this.emailHelpDesk}>{this.emailHelpDesk.substring(7)}</a></p><br></br> */}
                {/* <p>STIAMO LAVORANDO PER VOI!</p><br></br> */}
                <p>Maggiori informazioni sulla nuova piattaforma SIDAF sono reperibili ai seguenti link:</p>
                <a target={'_blank'} href="https://www.conaf.it/circolare-n-19-del-28-06-2023/">
                  www.conaf.it/circolare-n-19-del-28-06-2023
                </a>
                <br></br>
                <a target={'_blank'} href="https://www.conaf.it/circolare-n-21-del-07-07-2023/">
                  www.conaf.it/circolare-n-21-del-07-07-2023
                </a>
              </div>
            }
          />
        </Router>
      )
      return router
    } else {
      let router = (
        <Router>
          <Switch>
            <Route exact path={PATH_TO_HOME} component={Home} />
            <Route path={PATH_TO_RICERCA_ALBO_UNICO} component={RicercaIscrittiAlboUnico} />
            <Route path={PATH_TO_FO_COOKIE} component={Cookie} />
            <Route path={PATH_TO_FO_PRIVACY} component={Privacy} />
            <Route path={PATH_TO_FO_FEEDBACK_ACCESSIBILITA} component={FeedbackAccessibilita} />
            <Route path={PATH_TO_RISULTATI_RICERCA_ALBO_UNICO} component={RisultatiRicercaAlboUnico} />
            <Route path={PATH_TO_FO_ELENCO_EVENTI_CATALOGO} component={ElencoEventiCatalogo} />
            <Route path={PATH_TO_FO_DETTAGLIO_EVENTO_CATALOGO} component={DettaglioEventoCatalogo} />
            <Route path={PATH_TO_ERROR} component={GenericError} />
            <Route path={'/*'} component={NotFound} />
          </Switch>
          <SidafCookieBar />
        </Router>
      )

      return router
    }
  }
}

export default AppRouter
